import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const disable = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
`;

const StyledButton = styled.button`
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  outline: 0;
  overflow: hidden;
  text-transform: uppercase;
  transition: all 250ms ease-out;

  ${({ secondary }) => secondary && css`
    background: rgba(255, 255, 255, 0.2);
    border: 0;
    padding: 15px;

    &:hover, &:focus {
      background: rgba(255, 255, 255, 0.4);

      > svg rect {
        fill: currentColor;
      }
    }
  `};

  ${({ secondary, theme }) => !secondary && css`
    background: transparent;
    border: 3px solid ${theme.colors.white};
    padding: 15px 20px;

    &:hover, &:focus {
      background: ${theme.colors.white};
      color: ${theme.colors.primary};

      > svg rect {
        fill: currentColor;
      }
    }
  `};

  ${({ isSubmitting }) => isSubmitting && css`
    animation: ${disable} 250ms ease-out 300ms forwards;
  `};
`;

const Button = ({
  children, className, secondary, onClick, type, isSubmitting,
}) => (
  <StyledButton
    className={className}
    secondary={secondary}
    onClick={() => onClick()}
    type={type}
    isSubmitting={isSubmitting}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  isSubmitting: false,
  onClick: () => undefined,
  secondary: false,
  type: 'button',
};

export default Button;

import React from 'react';
import Input from './input';

const Page3 = () => (
  <>
    <Input
      type="text"
      label="First Name"
      name="secondary_firstName"
      required
      autoFocus
    />
    <Input
      type="text"
      label="Last Name"
      name="secondary_lastName"
      required
    />
    <Input
      type="text"
      label="Job title"
      name="secondary_jobTitle"
      required
    />
    <Input
      type="tel"
      label="Phone Number"
      name="secondary_phone"
      required
    />
    <Input
      type="email"
      label="Email"
      name="secondary_email"
      required
    />
  </>
);

export default Page3;

import React from 'react';
import styled from 'styled-components';
import Input from './input';
import Dropdown from './dropdown';
import Radio from './radio';
import CustomCheckbox from './checkbox';

const Group = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const Page1 = () => (
  <>
    <Input
      type="text"
      label="BAC #"
      name="bac"
      required
      autoFocus
    />
    <Input
      type="text"
      label="Dealership Name"
      name="dealership_name"
      required
    />
    <Input
      type="text"
      label="Address"
      name="dealership_address"
      required
    />
    <Input
      type="text"
      label="City"
      name="dealership_city"
      required
    />
    <Group>
      <Dropdown
        type="text"
        label="State"
        name="dealership_state"
        required
      >
        <option value="" disabled hidden>State</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </Dropdown>
      <Input
        type="text"
        label="Zip"
        name="dealership_zip"
        required
      />
    </Group>
    <CustomCheckbox
      title="Check all that apply."
      name="gmdtvcheckbox"
      options={[
        { id: 1, label: 'Cadillac Pinnacle / C.I. Program ', value: 'Cadillac' },
        { id: 2, label: 'IDDN Program (Buick, Chevrolet, GMC) ', value: 'IDDN' },
        { id: 3, label: 'GMDTV for Customer Lounge', value: 'GMDTVLounge' },
        { id: 4, label: 'Upgrade Device ', value: 'Upgrade' },
      ]}
      required
    />
  </>
);

export default Page1;
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled, { css } from 'styled-components';
import ErrorIcon from './error-icon';
import dropdownSrc from '../../assets/icon-dropdown.svg';

const Container = styled.div`
  margin-bottom: 16px;
  position: relative;
  width: 100%;

  &::after {
    content: url(${dropdownSrc});
    height: auto;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
  }
`;

const StyledDropdown = styled.select`
  appearance: none;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  height: 54px;
  font-size: 18px;
  line-height: inherit;
  outline: 0;
  margin: 0;
  padding: 3px 30px 0 10px;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }

  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${({ value }) => !value && css`
    color: rgba(64, 69, 64, 0.64);
  `};

  ${({ value }) => value && css`
    background-color: ${({ theme }) => theme.colors.white};

    + label {
      opacity: 0.64;
      top: 4px;
      transition: all 0.3s ease-in-out;
    }

    &:focus,
    &:active {
      + label {
        color: ${({ theme }) => theme.colors.primary};
        opacity: 1;
      }
    }
  `};

  ${({ error, submitFailed }) => (error && submitFailed) && css`
    z-index: 1;
  `};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.black};
  font-size: 11px;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const isRequired = (value, required) => required && !value;

const addProps = Component => ({
  input, // eslint-disable-line
  meta, // eslint-disable-line
  ...rest
}) => <Component {...input} {...meta} {...rest} />;

const StyledDropdownWithProps = addProps(StyledDropdown);

const Dropdown = ({
  children,
  label,
  name,
  required,
  type,
}) => (
  <Container>
    <Field
      component={StyledDropdownWithProps}
      id={name}
      name={name}
      type={type}
      validate={value => isRequired(value, required)}
    >
      {children}
    </Field>
    <Label htmlFor={name}>{label}</Label>
    <StyledErrorIcon name={name} />
  </Container>
);

Dropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

Dropdown.defaultProps = {
  required: false,
};

export default Dropdown;

import React from 'react';
import PropTypes from 'prop-types';

const IconArrowDotted = ({ className, color }) => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect y="4" width="2" height="2" fill={color} />
    <rect x="4" y="4" width="2" height="2" fill={color} />
    <rect x="8" y="4" width="2" height="2" fill={color} />
    <rect x="12" y="4" width="2" height="2" fill={color} />
    <rect x="12" y="8" width="2" height="2" fill={color} />
    <rect x="12" width="2" height="2" fill={color} />
    <rect x="16" y="4" width="2" height="2" fill={color} />
  </svg>
);

IconArrowDotted.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconArrowDotted.defaultProps = {
  className: '',
  color: 'white',
};

export default IconArrowDotted;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 16px;
  position: relative;
  width: 100%;
`;

const Title = styled.p`
  font-size: 18px;
  margin: 0;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
`;

const StyledCheckbox = styled.input`
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  position: absolute;

  + span {
    align-items: center;
    display: flex;

    &::before {
      background: rgba(255, 255, 255, 0.2);
      border: 8px solid transparent;
      border-radius: 4px;
      content: '';
      display: block;
      height: 24px;
      margin-right: 16px;
      transition: all 250ms ease-out;
      width: 24px;
    }

    &:hover {
      &::before {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }

  &:checked {
    + span {
      &::before {
        background: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
`;

const addProps = Component => ({
  input, // eslint-disable-line
  ...rest
}) => <Component {...input} {...rest} />;

const StyledCheckboxWithProps = addProps(StyledCheckbox);

const CustomCheckbox = ({
  title,
  name,
  options,
}) => (
  <Container>
    <Title>{title}</Title>
    <List>
      {options.map(({ id, label, value }) => (
        <li key={id}>
          <Label htmlFor={value}>
            <Field
              component={StyledCheckboxWithProps}
              id={value}
              name={name}
              type="checkbox"
              value={value}
            />
            <span>{label}</span>
          </Label>
        </li>
      ))}
    </List>

  </Container>
);

CustomCheckbox.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomCheckbox;

import React from 'react';
import { Link, navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContainerLeft from '../components/common/container-left';
import MainContent from '../components/common/main-content';
import ContainerRight from '../components/common/container-right';
import Button from '../components/common/button';
import Form from '../components/form';
import logoSrc from '../assets/logo2x.png';
import closeSrc from '../assets/icon-close.svg';

const fadeInSlide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Content = styled.div`
  @media(min-width: 960px) {
    display: flex;
    min-height: 100vh;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  max-width: 201px;

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
`;

const FormWrapper = styled.div`
  animation: ${fadeInSlide} 500ms ease-in-out 500ms forwards;
  opacity: 0;
  padding: 32px;
  position: relative;
  transform: translateY(-16px);

  @media(min-width: 960px) {
    padding: 10%;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Headline = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const StyledImage = styled.img`
  display: block;
`;

const EnrollPage = () => (
  <Layout>
    <SEO title="Enroll" keywords={['gmdn', 'gmdn enroll']} />
    <Content>
      <ContainerLeft>
        <StyledLink to="/">
          <img src={logoSrc} alt="GMDN Enroll" />
        </StyledLink>
        <MainContent />
      </ContainerLeft>
      <ContainerRight fullWidth>
        <FormWrapper>
          <Header>
            <Headline>Enroll Now</Headline>
            <Button secondary onClick={() => navigate('/')}>
              <StyledImage src={closeSrc} alt="Back to home" />
            </Button>
          </Header>
          <Form />
        </FormWrapper>
      </ContainerRight>
    </Content>
  </Layout>
);

export default EnrollPage;

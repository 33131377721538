import React from 'react';
import Input from './input';

const Page2 = () => (
  <>
    <Input
      type="text"
      label="First Name"
      name="primary_firstName"
      required
      autoFocus
    />
    <Input
      type="text"
      label="Last Name"
      name="primary_lastName"
      required
    />
    <Input
      type="text"
      label="Job title"
      name="primary_jobTitle"
      required
    />
    <Input
      type="tel"
      label="Phone Number"
      name="primary_phone"
      required
    />
    <Input
      type="email"
      label="Email"
      name="primary_email"
      required
    />
  </>
);

export default Page2;

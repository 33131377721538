import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import iconSrc from '../../assets/icon-warning.svg';

const ErrorIcon = ({ className, name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={
      ({ meta: { error, submitFailed } }) => (
        (error && submitFailed)
          ? <img className={className} src={iconSrc} alt="Required field" style={{ zIndex: 2 }} />
          : null
      )
    }
  />
);

ErrorIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

ErrorIcon.defaultProps = {
  className: '',
};

export default ErrorIcon;

import React from 'react';
import Input from './input';
import Radio from './radio';

const Page4 = () => (
  <>
    <Input
      type="text"
      label="First Name"
      name="it_firstName"
      required
      autoFocus
    />
    <Input
      type="text"
      label="Last Name"
      name="it_lastName"
      required
    />
    <Input
      type="text"
      label="Job title"
      name="it_jobTitle"
      required
    />
    <Input
      type="tel"
      label="Phone Number"
      name="it_phone"
      required
    />
    <Input
      type="email"
      label="Email"
      name="it_email"
      required
    />
    <Radio
      title="Is IT handled by an offsite company?"
      name="it_offsite"
      options={[
        { id: 1, label: 'Yes', value: 'yes' },
        { id: 2, label: 'No', value: 'no' },
      ]}
      required
    />
  </>
);

export default Page4;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import Button from '../common/button';
import ArrowDotted from '../../assets/icon-arrow-dotted';
import warningSrc from '../../assets/icon-warning.svg';

const FormHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 75px 0 20px 0;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

  ${({ secondary }) => secondary && css`
    opacity: 0.5;
  `};
`;

const MainWarning = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.warning};
  display: flex;
  font-size: 18px;
  margin-bottom: 20px;

  > img {
    margin: 5px 10px 0 0;
  }

  > p {
    margin: 0;
    width: 90%;
  }
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const NextButton = styled(Button)`
  align-self: flex-end;
`;

const PrevArrow = styled(ArrowDotted)`
  display: block;
  margin: 7px 3px;
  transform: rotate(180deg);
`;

const NextArrow = styled(ArrowDotted)`
  display: inline-block;
  margin-left: 40px;
  transition: transform 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);

  ${({ isSubmitting }) => isSubmitting && css`
    transform: translateX(100px);
  `};
`;

const focusOnError = createDecorator();

class Wizard extends Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues,
    };
  }

  next = values => this.setState(state => ({
    page: Math.min(state.page + 1, this.props.children.length - 1), // eslint-disable-line
    values,
  }))

  previous = () => this.setState(state => ({
    page: Math.max(state.page - 1, 0),
  }))

  /**
 * NOTE: Both validate and handleSubmit switching are implemented
 * here because 🏁 Redux Final Form does not accept changes to those
 * functions once the form has been defined.
 */

  validate = (values) => {
    const { children } = this.props; // eslint-disable-line
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  }

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props; // eslint-disable-line
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    }
    return this.next(values);
  }

  getPageTitle = (page) => {
    if (page === 0) return 'General Information';
    if (page === 1) return 'Primary Contact';
    if (page === 2) return 'Secondary Contact';
    if (page === 3) return 'IT Contact';
    return '';
  }

  render() {
    const { children } = this.props; // eslint-disable-line
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    const totalPages = React.Children.count(children);

    return (
      <Form
        initialValues={values}
        decorators={[focusOnError]}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({
          handleSubmit, submitting, submitFailed, valid,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormHeader>
              <Title>
                {this.getPageTitle(page)}
              </Title>
              <Title secondary>
                Step
                {' '}
                {page + 1}
                {' '}
                of
                {' '}
                {totalPages}
              </Title>
            </FormHeader>

            {(submitFailed && !valid) && (
              <MainWarning>
                <img src={warningSrc} alt="Form submit warning" />
                <p>
                  Whoops. Looks like you missed something.
                  Fill out the remaining fields to continue.
                </p>
              </MainWarning>
            )}

            {/* all passed in children from form.js
                will act as different steps in this form */}
            {activePage}

            <Buttons>
              {page > 0 ? (
                <Button secondary onClick={this.previous}>
                  <PrevArrow />
                </Button>
              ) : <div />}

              {!isLastPage && (
                <NextButton type="submit">
                  Next
                  <NextArrow />
                </NextButton>
              )}

              {isLastPage && (
                <Button type="submit" isSubmitting={submitting}>
                  Submit
                  <NextArrow isSubmitting={submitting} />
                </Button>
              )}
            </Buttons>
          </form>
        )}
      </Form>
    );
  }
}

Wizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
};

Wizard.defaultProps = {
  initialValues: {},
};

export default Wizard;

import React from 'react';
import { navigate } from 'gatsby';
import Wizard from './wizard';
import Page1 from './page-1';
import Page2 from './page-2';
import Page3 from './page-3';
import Page4 from './page-4';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const onSubmit = async (values) => {
  await sleep(500);

  fetch('https://gmdn-email.herokuapp.com/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values, 0, 2),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`unable to submit form: ${res.statusText || ''}`);
      }
      return res;
    })
    .then(() => {
      navigate('/thanks');
    })
    .catch((error) => {
      console.error('error: ', error);
    });

/*
  fetch('/.netlify/functions/leads', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(values, 0, 2),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`unable to submit form: ${res.statusText || ''}`);
      }
      return res;
    })
    .then(() => {
      navigate('/thanks');
    })
    .catch((error) => {
      console.error('error: ', error);
    });
*/

};

const FormContainer = () => (
  <Wizard
    initialValues={{ it_offsite: 'no' }}
    onSubmit={onSubmit}
  >
    <Wizard.Page>
      <Page1 />
    </Wizard.Page>
    <Wizard.Page>
      <Page2 />
    </Wizard.Page>
    <Wizard.Page>
      <Page3 />
    </Wizard.Page>
    <Wizard.Page>
      <Page4 />
    </Wizard.Page>
  </Wizard>
);

export default FormContainer;

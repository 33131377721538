import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled, { css } from 'styled-components';
import ErrorIcon from './error-icon';

const Container = styled.div`
  margin-bottom: 16px;
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  appearance: none;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.black};
  height: 54px;
  font-size: 18px;
  line-height: 1;
  outline: 0;
  margin: 0;
  padding: 0 10px;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;

  &::placeholder {
    color: rgba(64, 69, 64, 0.64);
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }

  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${({ value }) => value && css`
    background-color: ${({ theme }) => theme.colors.white};

    + label {
      color: rgba(64, 69, 64, 0.64);
      opacity: 1;
      top: 4px;
      transition: all 0.3s ease-in-out;
    }

    &:focus,
    &:active {
      + label {
        color: ${({ theme }) => theme.colors.primary};
        opacity: 1;
      }
    }
  `};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.black};
  font-size: 11px;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const isRequired = (value, required) => required && !value;

const addProps = Component => ({
  input, // eslint-disable-line
  ...rest
}) => <Component {...input} {...rest} />;

const StyledInputWithProps = addProps(StyledInput);

const Input = ({
  autoFocus,
  label,
  name,
  type,
  required,
}) => (
  <Container>
    <Field
      component={StyledInputWithProps}
      id={name}
      name={name}
      placeholder={label}
      type={type}
      validate={value => isRequired(value, required)}
      autoFocus={autoFocus}
    />
    <Label htmlFor={name}>{label}</Label>
    <StyledErrorIcon name={name} />
  </Container>
);

Input.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

Input.defaultProps = {
  autoFocus: false,
  required: false,
};

export default Input;
